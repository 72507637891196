import { post } from '@/utils/request';

// 活动创建
const create = (data) => post({
    url: '/store/market.Market/create',
    data: data
});

// 活动编辑
const update = (data) => post({
    url: '/store/market.Market/update',
    data: data
});

// 活动列表
const list = (data) => post({
    url: '/store/market.Market/list',
    data: data
})

// 活动详情
const detail = (data) => post({
    url: '/store/market.Market/detail',
    data: data
})

// 活动删除
const deleteSign = (data) => post({
    url: '/store/market.Market/delete',
    data: data
})


// 模板信息
const getMarketTemplateBasic = (data) => post({
    url: '/store/common.select/getMarketTemplateDetail',
    data: data
})

// 更改状态
const updateStatus = (data) => post({
    url: '/store/market.Market/updateStatus',
    data: data
})

// 签到统计
const getMarketStatist = (data) => post({
    url: '/store/market.MarketUserData/list',
    data: data
})

// 签到明细
const getMarketData = (data) => post({
    url: '/store/market.MarketUserJoin/list',
    data: data
})

// 签到核销列表
const getMarketVerifList= (data) => post({
    url: '/store/market.MarketDrawnUser/verifList',
    data: data
})

export {
    create,
    update,
    list,
    detail,
    deleteSign,
    getMarketTemplateBasic,
    updateStatus,
    getMarketStatist,
    getMarketData,
    getMarketVerifList
}