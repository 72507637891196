<template>
  <div>
    <div v-if="!isAdd && !isDetail">
      <div class="card" style="display: flex">
        <div class="left">
          <el-button
            type="primary"
            icon="el-icon-plus"
            slot="reference"
            @click="handleAdd"
            >新增签到</el-button
          >
        </div>
        <div class="line"></div>
        <div class="middle" style="width: 82%">
          <el-form :model="cond" class="middle" label-width="80px">
            <el-form-item label="活动名称" style="margin-right: 30px">
              <el-input
                clearable
                v-model="cond.title"
                autocomplete="off"
                placeholder="请输入活动名称"
              ></el-input
            ></el-form-item>
            <el-form-item label="活动状态">
              <el-select
                clearable
                v-model="cond.status"
                placeholder="请选择活动状态"
                style="width: 200px; margin-right: 10px"
              >
                <el-option label="全部" value=""></el-option>
                <el-option label="启用" :value="10"></el-option>
                <el-option label="停用" :value="20"></el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
        <div>
          <el-button
            type="primary"
            @click="searchData()"
            style="margin-left: 10px; height: 40px"
            >搜索</el-button
          >
          <el-button @click="handleReset" style="height: 40px">重置</el-button>
        </div>
      </div>
      <div class="table">
        <el-table :data="list" stripe>
          <el-table-column
            type="index"
            label="序号"
            width="80"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="title"
            label="活动名称"
            align="center"
          ></el-table-column>
          <el-table-column prop="status" align="center">
            <template slot="header">
              <el-tooltip
                class="item"
                effect="dark"
                content="停用规则：停用后，用户将无法签到"
                placement="top"
              >
                <div>
                  活动状态<i
                    class="el-icon-info"
                    style="margin-left: 5px; color: #409eff"
                  ></i>
                </div>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.status"
                :active-value="20"
                :inactive-value="30"
                @change="handleIsStart($event, scope.row)"
              ></el-switch> </template
          ></el-table-column>
          <el-table-column
            prop="client_system_name"
            label="绑定体系"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="store_user_name"
            label="创建人"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="create_time"
            label="创建时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="update_time"
            label="更新时间"
            align="center"
          ></el-table-column>
          <el-table-column prop="action" align="center">
            <template slot="header">
              <el-tooltip
                class="item"
                effect="dark"
                content="编辑规则：编辑后，停用旧规则，用户将重新匹配签到规则"
                placement="top"
              >
                <div>
                  操作<i
                    class="el-icon-info"
                    style="margin-left: 5px; color: #409eff"
                  ></i>
                </div>
              </el-tooltip>
            </template>
            <template slot-scope="scope">
              <div>
                <el-button type="text" @click="handleDetail(scope.row)"
                  >详情</el-button
                >
                <el-button
                  type="text"
                  @click="handleEdit(scope.row)"
                  v-if="scope.row.status !== 20"
                  >编辑</el-button
                >
                <el-button type="text" @click="handelDelete(scope.row)"
                  >删除</el-button
                >
                <el-button type="text" @click="handleReview(scope.row)"
                  >预览</el-button
                >
              </div>
              <!-- <div class="options">
                <el-select
                  v-model="scope.row.select"
                  placeholder="请选择"
                  @change="handleAction($event, scope.row)"
                >
                  <el-option label="详情" :value="2"></el-option>
                  <el-option label="编辑" :value="1"></el-option>
                  <el-option label="删除" :value="3"></el-option>
                  <el-option label="预览" :value="4"></el-option>
                </el-select>
              </div> -->
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
    <Add v-if="isAdd" :market_id="market_id" @hide="getSignList" />
    <ActivityData v-if="isDetail" :market_id="market_id" @hide="getSignList" />
    <el-dialog
      :visible.sync="previewVisiable"
      title="活动预览"
      :destroy-on-close="true"
      :before-close="cancelPreview"
    >
      <Preview
        :activityInfo="activityInfo"
        @hide="getSignList"
        @edit="handleEdit"
        v-if="previewVisiable"
      />
    </el-dialog>
  </div>
</template>

<script>
import {
  list,
  deleteSign,
  updateStatus,
} from "@/api/marketing/basicActivity/index.js";
import ActivityData from "./ActivityData";
import Add from "./Add";
import Preview from "../../../marketing/activity/activityList/preview";

export default {
  data() {
    return {
      previewVisiable: false,
      isDetail: true,
      isAdd: false,
      list: [],
      activityInfo: {},
      cond: {
        market_template_function: 20,
      },
      market_id: 0,
    };
  },
  created() {
    this.getSignList();
  },
  components: {
    Add,
    ActivityData,
    Preview,
  },
  methods: {
    cancelPreview() {
      this.previewVisiable = false;
      this.getSignList();
    },
    getSignList() {
      this.isDetail = false;
      this.isAdd = false;
      this.market_id = 0;
      list({ ...this.cond }).then((res) => {
        if (res.code === 1) {
          this.list = res.data.list;
        }
      });
    },
    handleAdd() {
      this.isAdd = true;
    },
    searchData() {
      this.getSignList();
    },
    handleReset() {
      this.cond = { market_template_function: 20 };
      this.getSignList();
    },
    handleDetail(row) {
      this.isDetail = true;
      this.market_id = row.market_id;
    },
    handleEdit(row) {
      this.isAdd = true;
      this.market_id = row.market_id;
    },
    handleReview(row) {
      this.previewVisiable = true;
      this.activityInfo = row;
    },

    /*
     *  删除
     */
    handelDelete(row) {
      this.$confirm("确定要删除吗？删除后将无法恢复。", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteSign({ market_id: row.market_id })
            .then((res) => {
              if (res.code === 1) {
                this.$message({
                  type: "success",
                  message: "删除成功！",
                });
                this.getSignList();
              } else {
              }
            })
            .catch((res) => {
              this.getSignList();
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
          this.getSignList();
        });
    },
    handleIsStart(event, row) {
      const data = {
        market_id: row.market_id,
        status: event,
        market_template_category_id:4
      };
      updateStatus(data).then((res) => {
        if (res.code === 1) {
          this.$message({
            type: "success",
            message: "更新成功！",
          });
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.middle {
  display: flex;
  height: 40px;
  overflow: hidden;
}
</style>
