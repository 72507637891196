<template>
  <div>
    <div class="card">
      <el-page-header @back="goBack" content="活动数据"></el-page-header>
    </div>
    <div class="content">
      <div class="toolTitle">
        <div
          class="box"
          v-for="(item, index) in tabs1"
          :key="index"
          :style="selectTool === item ? 'color: #2791FF' : ''"
          @click="changeTool(item)"
        >
          {{ item }}
          <div class="span" v-if="selectTool === item"></div>
        </div>
      </div>
    </div>
    <div class="activityData" v-if="selectTool === '活动详情'">
      <div class="content" style="padding-bottom: 15px">
        <div class="activityTittle">
          <div class="span"></div>
          活动信息
        </div>
        <div class="activityMain">
          <div class="activityInfo">
            <div class="activityLine">
              <div class="activityInfoTitle">活动标题</div>
              <div class="content">{{ info.title }}</div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">绑定体系</div>
              <div class="content">{{ info.client_system_name }}</div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">活动状态</div>
              <div class="content">
                {{ info.status === 20 ? "启用" : "暂停" }}
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">创建时间</div>
              <div class="content">{{ info.create_time }}</div>
            </div>
            <!-- <div class="activityLine">
              <div class="activityInfoTitle">头图</div>
              <div class="content">
                <img
                  :src="info.head_image || noPic"
                  style="width: 70px; height: 70px; border-radius: 7px"
                />
              </div>
            </div> -->
            <div class="activityLine">
              <div class="activityInfoTitle">分享标题</div>
              <div class="content">{{ info.share_title }}</div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">分享图片</div>
              <div class="content">
                <img
                  :src="info.share_image || noPic"
                  style="width: 70px; height: 70px; border-radius: 7px"
                />
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">分享内容</div>
              <div class="content">{{ info.share_content }}</div>
            </div>
          </div>
          <div class="activityInfo">
            <div class="activityLine">
              <div class="activityInfoTitle">背景音乐</div>
              <div class="content">{{ info.music_name }}</div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">活动规则</div>
              <div class="content">{{ info.explain }}</div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">活动详情图</div>
              <div class="content">
                <div></div>
                <img
                  :src="noPic"
                  style="width: 70px; height: 70px; border-radius: 7px"
                  v-if="info.images.length === 0"
                />
                <template v-for="(item, index) in info.images" v-else>
                  <img
                    :src="item"
                    :key="index"
                    style="
                      width: 70px;
                      height: 70px;
                      border-radius: 7px;
                      margin-right: 5px;
                    "
                  />
                </template>
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">每天签到奖励</div>
              <div class="content">
                <div v-if="info.everyday_integral">
                  积分(每天固定奖励)： +{{ info.everyday_integral }}；<span
                    v-if="info.overlay_integral"
                    ><br />递增奖励：第二天起递增{{
                      info.overlay_integral
                    }}积分，{{
                      info.stop_day_integral
                        ? info.stop_day_integral + "天后，不再递增"
                        : "不限天数"
                    }}</span
                  >
                </div>
                <div v-if="info.everyday_storage_money" style="margin-top: 5px">
                  储值金(每天固定奖励)： +{{
                    info.everyday_storage_money
                  }}；<span v-if="info.overlay_storage_money"
                    ><br />递增奖励：第二天起递增{{
                      info.overlay_storage_money
                    }}储值金，{{
                      info.stop_day_storage_money
                        ? info.stop_day_storage_money + "天后，不再递增"
                        : "不限天数"
                    }}</span
                  >
                </div>
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">连续签到奖励</div>
              <div class="content">
                <template v-for="(item, index) in market_goods">
                  <div :key="index">
                    连续签到{{ item.continue_day }}天，
                    <span v-if="item.number !== 0" style="margin-right: 5px"
                      >积分：+{{ item.number }}；</span
                    >
                    <span v-if="item.money !== 0"
                      >储值金：+{{ item.money }}；</span
                    >
                    <span
                      v-if="item.goods_list && item.goods_list.length !== 0"
                    >
                      奖品：<template v-for="(items, indexs) in item.goods_list"
                        ><span :key="indexs"
                          >{{ items.goods_name }}
                        </span></template
                      >
                    </span>
                  </div>
                </template>
              </div>
            </div>
          </div>
          <div class="activityInfo">
            <div class="activityLine">
              <div class="activityInfoTitle">核销时间</div>
              <div class="content">
                {{
                  !info.confirm_day
                    ? "不限时间"
                    : "奖品发放后，" + info.confirm_day + "天有效"
                }}
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">核销密码</div>
              <div class="content">
                {{ info.verif_password }}
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">签到规则</div>
              <div class="content">
                超过连续签到天数后，{{
                  info.continue_over_status === "10"
                    ? "重新计算连续天数，继续赠送"
                    : "不再赠送（断签后重新达标也不再赠送）"
                }}
              </div>
            </div>
            <div class="activityLine">
              <div class="activityInfoTitle">赠送规则</div>
              <div class="content">
                到达连续签到天数后，{{
                  info.continue_give_status === "10"
                    ? "送每天固定奖励+连续签到的奖励"
                    : "仅送连续签到的奖励"
                }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="content" style="margin-top: 10px">
        <div class="activityTittle">
          <div class="span"></div>
          奖品信息
        </div>
        <div class="prizeList">
          <div
            class="prizeBox"
            v-for="(item, index) in prize_goods"
            :key="index"
          >
            <div class="prizeImg">
              <img :src="item.goods_pic" />
            </div>
            <div class="prizeContent">
              <div>连续签到{{ item.continue_day }}天奖品</div>
              <div>奖品名称：{{ item.goods_name }}</div>
              <div style="margin-top: 3px">
                共{{ item.goods_count_total }}件 / 剩余{{ item.goods_count }}件
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="activityData" v-if="selectTool === '签到统计'">
      <div class="search" style="margin-top: 10px; border: 1px solid #e8e8e8">
        <div :style="{ height: statisHeight }" class="wrapper">
          <div class="middle" id="middle">
            <el-form
              :model="signStatisCond"
              class="form"
              style="overflow: hidden; display: flex; flex-wrap: wrap"
              label-width="100px"
            >
              <el-form-item label="会员名称" style="margin-right: 30px">
                <el-input
                  clearable
                  v-model="signStatisCond.username"
                  autocomplete="off"
                  placeholder="请输入会员名称"
                ></el-input
              ></el-form-item>
              <el-form-item label="会员手机号" style="margin-right: 30px">
                <el-input
                  clearable
                  v-model="signStatisCond.mobile"
                  autocomplete="off"
                  placeholder="请输入会员手机号"
                ></el-input
              ></el-form-item>
              <el-form-item label="累计签到天数" style="margin-right: 30px">
                <el-input
                  style="width: 90px"
                  clearable
                  v-model="signStatisCond.toatal_sign_start"
                  autocomplete="off"
                  placeholder="请输入"
                />
                天 -
                <el-input
                  style="width: 90px"
                  clearable
                  v-model="signStatisCond.toatal_sign_end"
                  autocomplete="off"
                  placeholder="请输入"
                />
                天
              </el-form-item>
              <el-form-item label="连续签到天数" style="margin-right: 30px">
                <el-input
                  style="width: 90px"
                  clearable
                  v-model="signStatisCond.max_continue_start"
                  autocomplete="off"
                  placeholder="请输入"
                />
                天 -
                <el-input
                  style="width: 90px"
                  clearable
                  v-model="signStatisCond.max_continue_end"
                  autocomplete="off"
                  placeholder="请输入"
                />
                天
              </el-form-item>
              <el-form-item label="最近签到时间" style="margin-right: 30px">
                <el-date-picker
                  v-model="signStatisCond.join_time"
                  type="date"
                  value-format="yyyy-MM-DD"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
              <el-form-item label="首次签到时间">
                <el-date-picker
                  v-model="signStatisCond.first_join_time"
                  type="date"
                  value-format="yyyy-MM-DD"
                  placeholder="选择日期"
                >
                </el-date-picker>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div>
          <el-button
            @click="handleHeight"
            style="
              width: 40px;
              height: 40px;
              line-height: 36px;
              text-align: center;
              padding: 0;
            "
          >
            <i class="el-icon-caret-bottom" v-if="statisHeight === '55px'"></i>
            <i class="el-icon-caret-top" v-else></i>
          </el-button>
          <el-button
            type="primary"
            @click="getMarketStatist()"
            style="height: 40px"
            >搜索</el-button
          >
          <el-button @click="resetSignStatist()" style="height: 40px"
            >重置</el-button
          >
        </div>
      </div>
      <div class="table">
        <el-table :data="signStatisList" stripe>
          <el-table-column
            type="index"
            label="序号"
            width="80"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="username"
            label="会员名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="mobile"
            label="会员手机号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="first_join_time"
            label="首次签到时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="join_time"
            label="最近签到时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="join_count"
            label="累计签到天数"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="max_continue_count"
            label="最长连续签到天数"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="total_integral"
            label="奖励总积分"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="total_storage_money"
            label="获得总储值金"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="total_goods"
            label="获得奖品总数"
            align="center"
          ></el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :total="signStatisTotal"
          :page-size="15"
          @current-change="handleCurrentSignStatis"
        ></el-pagination>
      </div>
    </div>
    <div class="activityData" v-if="selectTool === '签到明细'">
      <div class="search" style="margin-top: 10px; border: 1px solid #e8e8e8">
        <div class="middle" id="middle">
          <el-form
            :model="signUserJoinCond"
            class="form"
            style="overflow: hidden; display: flex; flex-wrap: wrap"
            label-width="100px"
          >
            <el-form-item label="会员名称" style="margin-right: 30px">
              <el-input
                clearable
                v-model="signUserJoinCond.username"
                autocomplete="off"
                placeholder="请输入会员名称"
              ></el-input
            ></el-form-item>
            <el-form-item label="会员手机号" style="margin-right: 30px">
              <el-input
                clearable
                v-model="signUserJoinCond.mobile"
                autocomplete="off"
                placeholder="请输入会员手机号"
              ></el-input
            ></el-form-item>
            <el-form-item label="签到时间" style="margin-right: 30px">
              <el-date-picker
                type="daterange"
                v-model="signUserJoinCond.time"
                range-separator="至"
                start-placeholder="浏览开始日期"
                end-placeholder="浏览结束日期"
              ></el-date-picker
            ></el-form-item>
          </el-form>
        </div>
        <div>
          <el-button
            type="primary"
            @click="getMarketSignData()"
            style="height: 40px"
            >搜索</el-button
          >
          <el-button @click="resetMarketSignData()" style="height: 40px"
            >重置</el-button
          >
        </div>
      </div>
      <div class="table">
        <el-table :data="signUserJoinList" stripe>
          <el-table-column
            type="index"
            label="序号"
            align="center"
            width="80"
          ></el-table-column>
          <el-table-column
            prop="username"
            label="会员名称"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="mobile"
            label="会员手机号"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="create_time"
            label="签到时间"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="integral"
            label="奖励积分"
            align="center"
          ></el-table-column>
          <el-table-column
            prop="money"
            label="奖励储值金"
            align="center"
          ></el-table-column>
          <el-table-column prop="prize" label="奖励奖品" align="left" width="700">
            <template slot-scope="scope">
              <div
                v-if="scope.row.prize_info && scope.row.prize_info.length !== 0"
                style="display: flex; flex-wrap: wrap"
              >
                <template v-for="(item, index) in scope.row.prize_info">
                  <div :key="index" style="margin-right: 10px">
                    {{ item.goods_name }}
                  </div>
                </template>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :total="signUserJoinTotal"
          :page-size="15"
          @current-change="handleCurrentSignUserJoin"
        ></el-pagination>
      </div>
    </div>
    <div class="activityData" v-if="selectTool === '核销记录'">
      <div class="search" style="margin-top: 10px; border: 1px solid #e8e8e8">
        <div :style="{ height: writeOffHeight }" class="wrapper">
          <div class="middle" id="middle">
            <el-form
              :model="verifCond"
              class="form"
              style="overflow: hidden; display: flex; flex-wrap: wrap"
              label-width="100px"
            >
              <el-form-item label="奖品名称" style="margin-right: 10px">
                <el-input
                  clearable
                  v-model="verifCond.goods_name"
                  autocomplete="off"
                  placeholder="请输入奖品名称"
                  maxlength="10"
                  show-word-limit
                ></el-input
              ></el-form-item>
              <el-form-item label="用户名称" style="margin-right: 10px">
                <el-input
                  clearable
                  v-model="verifCond.username"
                  autocomplete="off"
                  placeholder="请输入用户名称"
                ></el-input
              ></el-form-item>
              <el-form-item label="用户手机号" style="margin-right: 10px">
                <el-input
                  clearable
                  v-model="verifCond.mobile"
                  autocomplete="off"
                  placeholder="请输入用户手机号"
                ></el-input
              ></el-form-item>
              <el-form-item label="核销人" style="margin-right: 10px">
                <el-input
                  clearable
                  v-model="verifCond.title"
                  autocomplete="off"
                  placeholder="请输入核销人"
                ></el-input
              ></el-form-item>
              <el-form-item label="核销状态">
                <el-select
                  v-model="verifCond.is_verif"
                  placeholder="请选择核销状态"
                  style="width: 200px; margin-right: 10px"
                >
                  <el-option label="未核销" :value="10"></el-option>
                  <el-option label="已核销" :value="20"></el-option>
                </el-select>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div>
          <el-button
            @click="handleVerifHeight"
            style="
              width: 40px;
              height: 40px;
              line-height: 36px;
              text-align: center;
              padding: 0;
            "
          >
            <i
              class="el-icon-caret-bottom"
              v-if="writeOffHeight === '55px'"
            ></i>
            <i class="el-icon-caret-top" v-else></i>
          </el-button>
          <el-button
            type="primary"
            @click="getMarketSignVerifList()"
            style="height: 40px"
            >搜索</el-button
          >
          <el-button style="height: 40px" @click="resetVerifList"
            >重置</el-button
          >
          <el-button
            type="primary"
            plain
            @click="handleExportDrawnUser"
            style="margin-right: 10px"
            icon="el-icon-upload2"
            >导出</el-button
          >
        </div>
      </div>
      <div class="writeData">
        <div style="margin-left: 30px">
          核销总数量<span style="color: #2791ff; margin-left: 10px">{{
            verif_total_num
          }}</span>
        </div>
        <div class="line"></div>
        <div>
          未核销数量<span style="color: #2791ff; margin-left: 10px">{{
            un_verif
          }}</span>
        </div>
        <div class="line"></div>
        <div>
          已核销数量<span style="color: #2791ff; margin-left: 10px">{{
            verif
          }}</span>
        </div>
        <div class="line"></div>
        <div style="font-size: 12px; color: #909399">
          实时数据 {{ nowTime }}
        </div>
      </div>
      <div class="table" style="margin-top: 10px; border: 1px solid #e8e8e8">
        <el-table :data="verifList" stripe height="700">
          <el-table-column
            align="center"
            type="index"
            label="序号"
          ></el-table-column>
          <el-table-column align="center" prop="headImg" label="头像">
            <template slot-scope="scope">
              <div class="headImg">
                <img :src="scope.row.head_img" /></div></template
          ></el-table-column>
          <el-table-column
            align="center"
            prop="username"
            label="用户名称"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="mobile"
            label="手机号"
          ></el-table-column>
          <el-table-column align="center" prop="goods_pic" label="奖品图片">
            <template slot-scope="scope">
              <div class="goods_Img">
                <img :src="scope.row.goods_pic" /></div></template
          ></el-table-column>
          <el-table-column
            align="center"
            prop="goods_name"
            label="奖品名称"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="hit_time"
            label="获奖时间"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="verif_hierarchy_name"
            label="选择领奖门店"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="final_verif_hierarchy_name"
            label="核销门店"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="verif_user_name"
            label="核销人"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="verif_time"
            label="核销时间"
          ></el-table-column>

          <el-table-column align="center" prop="is_receive" label="核销状态">
            <template slot-scope="scope">
              {{ scope.row.is_verif === 10 ? "未核销" : "已核销" }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="action" label="操作">
            <template slot-scope="scope">
              <el-button type="text" @click="handleVerif(scope.row)">{{
                scope.row.is_verif === 20 ? "查看详情" : "核销"
              }}</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagina">
        <el-pagination
          background
          layout="total, prev, pager, next, jumper"
          :total="verifTotal"
          :page-size="15"
          @current-change="handleCurrentVerifChange"
        ></el-pagination>
      </div>
    </div>
    <el-dialog
      :visible.sync="visiable"
      width="30%"
      title="核销详情"
      :destroy-on-close="true"
      :before-close="cancelVerifer"
    >
      <div class="title">
        <div class="span"></div>
        会员信息
      </div>
      <div style="display: flex">
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">会员名称</div>
          <div>{{ veriferInfo.username }}</div>
        </div>
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">手机号</div>
          <div>{{ veriferInfo.mobile }}</div>
        </div>
      </div>
      <div class="title" style="margin-top: 15px">
        <div class="span"></div>
        奖品信息
      </div>
      <div>
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">奖品名称</div>
          <div>
            {{
              veriferInfo.market_goods_json &&
              veriferInfo.market_goods_json.goods_name
            }}
          </div>
        </div>
      </div>
      <div>
        <div style="margin-top: 15px; display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">奖品图片</div>
          <div>
            <img
              :src="
                veriferInfo.market_goods_json &&
                veriferInfo.market_goods_json.goods_pic
              "
              style="width: 94px; height: 64px; object-fit: cover"
            />
          </div>
        </div>
      </div>
      <div>
        <div style="margin-top: 15px; display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">获奖时间</div>
          <div>{{ veriferInfo.create_time }}</div>
        </div>
      </div>
      <div class="title" style="margin-top: 15px">
        <div class="span"></div>
        兑奖信息
      </div>
      <div style="display: flex">
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">领取门店</div>
          <div>{{ veriferInfo.hierarchy_name }}</div>
        </div>
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">门店地址</div>
          <div>{{ veriferInfo.hierarchy_addr }}</div>
        </div>
      </div>
      <div style="display: flex; margin-top: 10px">
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">门店电话</div>
          <div>{{ veriferInfo.hierarchy_phone_num }}</div>
        </div>
      </div>
      <div style="position: absolute; top: 35%; right: 10%; z-index: 5555">
        <img
          src="@/assets/images/activity/writeOffed.svg"
          style="transform: rotate(-10deg)"
          v-if="veriferInfo.is_verif === 20"
        />
        <img
          src="@/assets/images/activity/writeOff.svg"
          style="transform: rotate(-10deg)"
          v-if="veriferInfo.is_verif === 10"
        />
      </div>
      <div
        class="title"
        style="margin-top: 15px"
        v-if="veriferInfo.is_verif === 20"
      >
        <div class="span"></div>
        核销信息
      </div>
      <div style="display: flex" v-if="veriferInfo.is_verif === 20">
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">核销人员</div>
          <div>{{ veriferInfo.verif_user_name }}</div>
        </div>
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">核销时间</div>
          <div>{{ veriferInfo.verif_time }}</div>
        </div>
      </div>
      <div
        style="display: flex; margin-top: 10px"
        v-if="veriferInfo.is_verif === 20"
      >
        <div style="display: flex; width: 50%">
          <div style="color: #909399; margin-right: 20px">核销门店</div>
          <div>{{ veriferInfo.final_verif_hierarchy_name }}</div>
        </div>
      </div>
      <div style="margin-top: 15px; text-align: center">
        <el-button
          type="primary"
          plain
          @click="cancelVerifer"
          v-if="veriferInfo.is_verif === 20"
          >取消</el-button
        >
        <el-button
          type="primary"
          @click="handleWriteOff"
          v-if="veriferInfo.is_verif === 10"
          >核销</el-button
        >
        <el-button
          type="primary"
          @click="cancelVerifer"
          v-if="veriferInfo.is_verif === 20"
          >确认</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  detail,
  getMarketStatist,
  getMarketData,
  getMarketVerifList,
} from "@/api/marketing/basicActivity/index.js";
import { verifDetails } from "@/api/marketing/activity/index.js";
import {
  handleExportDrawnUser,
  checkVerificationCode,
} from "@/api/marketing/activity/index";
const noPic = require("@/assets/images/nopic.png");
import { dateFormat } from "../../../../utils/method";

export default {
  data() {
    return {
      writeOffHeight: "55px",
      statisHeight: "55px",
      noPic: noPic,
      tabs1: ["活动详情", "签到统计", "签到明细", "核销记录"],
      selectTool: "活动详情",
      info: {},
      market_goods: [],
      goods_list: [],
      signStatisCond: {
        page: 1,
        page_num: 15,
      },
      signStatisList: [],
      signStatisTotal: 0,
      signUserJoinCond: {
        page: 1,
        page_num: 15,
      },
      signUserJoinList: [],
      signUserJoinTotal: 0,
      verifList: [],
      verifCond: {
        page: 1,
        page_num: 15,
      },
      verifTotal: 0,
      verif_total_num: 0,
      un_verif: 0,
      verif: 0,
      nowTime: null, // 实时数据时间
      prize_goods: [],
      visiable: false,
      veriferInfo: {},
    };
  },
  created() {
    this.nowTime = dateFormat("YYYY-mm-dd HH:MM:SS", new Date());
    this.getSignInfo();
  },
  props: {
    market_id: Number,
  },
  methods: {
    handleHeight() {
      if (parseInt(this.offsetHeight) <= 62) {
        this.$message.warning("没有更多条件");
        return;
      }
      this.statisHeight =
        this.statisHeight === "55px" ? this.offsetHeight : "55px";
    },
    handleVerifHeight() {
      if (parseInt(this.offsetHeight) <= 62) {
        this.$message.warning("没有更多条件");
        return;
      }
      this.writeOffHeight =
        this.writeOffHeight === "55px" ? this.offsetHeight : "55px";
    },
    goBack() {
      this.$emit("hide");
    },
    cancelVerifer() {
      this.veriferInfo = {};
      this.visiable = false;
    },
    handleWriteOff() {
      checkVerificationCode({ code: this.veriferInfo.verif_code }).then(
        (res) => {
          if (res.code === 1) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.cancelVerifer();
            this.getMarketSignVerifList();
          }
        }
      );
    },
    getSignInfo() {
      detail({ market_id: this.market_id, market_template_category_id:4}).then((res) => {
        if (res.code === 1) {
          res.data.images =
            res.data.images !== "" ? res.data.images.split(",") : [];
          this.info = { ...res.data, ...res.data.market_json };
          this.prize_goods = res.data.prize_goods;
          this.market_goods = res.data.market_goods.map((item) => {
            item.number = 0;
            item.money = 0;
            item.goods_list.forEach((items) => {
              if (items.type === 40) {
                item.number = items.market_value;
              }
              if (items.type === 50) {
                item.money = items.market_value;
              }
              item.goods_list = item.goods_list.filter(
                (item) => item.type === 10 || item.type === 20
              );
              this.goods_list = this.goods_list.concat(item.goods_list);
            });
            return item;
          });
        }
      });
    },
    resetSignStatist() {
      this.signStatisCond = {
        page: 1,
        page_num: 15,
      };
      this.getMarketStatist();
    },
    /*
     *签到统计
     */
    getMarketStatist() {
      getMarketStatist({
        market_id: this.market_id,
        ...this.signStatisCond,
      }).then((res) => {
        if (res.code === 1) {
          this.signStatisList = res.data.list;
          this.signStatisTotal = res.data.total;
        }
      });
    },
    handleCurrentSignStatis(val) {
      this.signStatisCond.page = val;
      this.getMarketStatist();
    },
    resetMarketSignData() {
      this.signUserJoinCond = {
        page: 1,
        page_num: 15,
      };
      this.getMarketSignData();
    },
    handleCurrentSignUserJoin(val) {
      this.signUserJoinCond.page = val;
      this.getMarketSignData();
    },
    /*
     *  签到明细
     */
    getMarketSignData() {
      getMarketData({
        market_id: this.market_id,
        ...this.signUserJoinCond,
      }).then((res) => {
        if (res.code === 1) {
          res.data.list = res.data.list.map((item) => {
            item.integral = item.prize_info.find(
              (prize) => prize.market_type === 40
            ).market_value;
            item.money = item.prize_info.find(
              (prize) => prize.market_type === 50
            ).market_value;
            return item;
          });
          this.signUserJoinList = res.data.list;
          this.signUserJoinTotal = res.data.total;
        }
      });
    },
    resetVerifList() {
      this.verifCond = {
        page: 1,
        page_num: 15,
      };
      this.getMarketSignVerifList();
    },
    handleCurrentVerifChange(val) {
      this.verifCond.page = val;
      this.getMarketSignVerifList();
    },
    /*
     *  签到核销列表
     */
    getMarketSignVerifList() {
      getMarketVerifList({ market_id: this.market_id, ...this.verifCond }).then(
        (res) => {
          if (res.code === 1) {
            this.verifList = res.data.list.list;
            this.verifTotal = res.data.list.total;
            this.verif_total_num = res.data.total_num;
            this.verif = res.data.verif;
            this.un_verif = res.data.un_verif;
          }
        }
      );
    },
    /*
     *  核销记录导出
     */
    handleExportDrawnUser() {
      handleExportDrawnUser({
        market_id: this.market_id,
        ...this.verifCond,
      }).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const a = document.createElement("a");
        a.setAttribute("download", `核销记录导出.xlsx`);
        a.href = URL.createObjectURL(blob);
        a.click();
      });
    },
    changeTool(item) {
      this.selectTool = item;
      switch (item) {
        case "活动详情":
          this.getSignInfo();
          break;
        case "签到统计":
          this.getMarketStatist();
          break;
        case "签到明细":
          this.getMarketSignData();
          break;
        case "核销记录":
          this.getMarketSignVerifList();
          break;
        default:
          break;
      }
    },
    handleVerif(row) {
      this.visiable = true;
      verifDetails({ code: row.verif_code }).then((res) => {
        if (res.code === 1) {
          this.veriferInfo = res.data.info;
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  transition: all 0.3s;
  overflow: hidden;
  flex: 1;
  .middle {
    display: flex;
    overflow: hidden;
  }
}
.search {
  display: flex;
  padding-top: 10px;
  padding-left: 20px;
  opacity: 1;
  background: linear-gradient(0deg, #f3f9ff 1%, #ffffff 100%);
  font-size: 14px;
}
.toolTitle {
  display: flex;
  height: 50px;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-radius: 0 0 6px 6px;

  .box {
    width: 150px;
    text-align: center;
    line-height: 50px;
    font-size: 14px;
    color: #333333;
    .span {
      margin: -3px auto 0 auto;
      width: 20px;
      height: 4px;
      background: #5493f5;
      border-radius: 2px;
    }
  }
  .box:hover {
    cursor: pointer;
  }
}
.activityData {
  margin-top: 10px;
}
.activityInfo {
  width: 30%;
  .activityLine {
    margin-bottom: 15px;
    display: flex;
    width: 100%;
    .activityInfoTitle {
      width: 30%;
      text-align: right;
      color: #909399;
    }
    .content {
      width: 70%;
      text-align: left;
      margin-left: 20px;
    }
  }
}
.activityMain {
  display: flex;
  width: 100%;
}
.activityTittle {
  padding-left: 30px;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 55px;
  line-height: 55px;
  font-size: 14px;
  font-weight: 600;

  .span {
    margin: auto 6px auto 0;
    width: 4px;
    height: 12px;
    background: #5493f5;
    border-radius: 2px;
  }
}
.writeData {
  display: flex;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  border-top: none;
  border: 1px solid #e8e8e8;
  border-top: none;
  border-radius: 0 0 4px 4px;
  background-color: white;
  .line {
    margin: auto 30px;
    width: 1px;
    height: 26px;
    background: #e9e9e9;
  }
}
.headImg {
  margin: 0 auto;
  width: 32px;
  height: 32px;
  img {
    width: 32px;
    height: 32px;
    border-radius: 50%;
  }
}
.goods_Img {
  margin: 8px auto;
  text-align: center;
  width: 54px;
  height: 54px;
  opacity: 1;
  img {
    width: 54px;
    height: 54px;
  }
}
.prizeList {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 20px;
  margin-left: 30px;
  .prizeBox {
    margin-right: 15px;
    margin-bottom: 15px;
    display: flex;
    padding: 15px 15px 15px 15px;
    opacity: 1;
    background: #f9f9f9;
    border-radius: 6px;

    .prizeImg {
      width: 80px;
      height: 80px;
      border-radius: 6px;
      img {
        width: 80px;
        height: 80px;
        border-radius: 6px;
      }
    }
    .prizeContent {
      padding-top: 8px;
      margin-left: 10px;
      .prizeName {
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}
.title {
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 600;

  .span {
    margin: auto 6px auto 0;
    width: 4px;
    height: 12px;
    background: #5493f5;
    border-radius: 2px;
  }
}
</style>
