<template>
  <div>
    <el-form
      :model="activityInfo"
      class="middle"
      label-width="180px"
      ref="activityInfo"
      :rules="rules"
    >
      <div style="margin-bottom: 60px">
        <div
          class="speed"
          :style="speed !== '基本信息' ? ' border-radius:7px' : ''"
        >
          <div class="li" style="color: #2791ff">
            <img
              src="../../../../assets/images/activity/icon_current.png"
              v-if="speed === '基本信息'"
            />
            <img
              src="../../../../assets/images/activity/icon_carry_out.png"
              v-if="speed !== '基本信息'"
            />
            基本信息
            <i class="right"></i>
          </div>
          <div
            class="li"
            :style="speed !== '基本信息' ? 'color: #2791ff' : null"
          >
            <img
              src="../../../../assets/images/activity/icon_not_selected.png"
              v-if="speed !== '签到规则'"
            />
            <img
              src="../../../../assets/images/activity/icon_current.png"
              v-if="speed === '签到规则'"
            />
            签到规则
          </div>
        </div>
        <div
          class="content"
          style="margin-top: 15px; border-radius: 6px"
          v-if="speed === '基本信息'"
        >
          <div class="title">
            <div class="span"></div>
            基本设置
          </div>
          <div class="cutLine" style="margin-bottom: 20px"></div>
          <el-form-item label="活动名称" prop="title">
            <el-input
              clearable
              v-model="activityInfo.title"
              autocomplete="off"
              maxlength="20"
              show-word-limit
              placeholder="请输入活动名称"
              style="width: 30%"
            ></el-input
          ></el-form-item>
          <el-form-item label="会员体系" prop="client_system_id">
            <el-select
              v-model="activityInfo.client_system_id"
              placeholder="请选择会员体系"
              style="width: 30%"
            >
              <template v-for="item in systemList">
                <el-option
                  :label="item.title"
                  :value="item.client_system_id"
                  :key="item.client_system_id"
                ></el-option>
              </template> </el-select
          ></el-form-item>
          <!-- <el-form-item label="头图">
            <el-upload
              class="avatar-uploader"
              action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
              :data="{
                directory: 'AGENT_INFO',
              }"
              :headers="{
                authorization: token,
              }"
              :show-file-list="false"
              :on-success="handleHeadImageSuccess"
            >
              <img v-if="head_image" :src="head_image" class="avatarOther" />
              <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
                style="background-color: white"
              ></i>
            </el-upload>
            <div style="color: #909399">
              放置在活动主图上方的图片，图片格式为jpg、png、gif，图片大小不超过500KB
            </div>
          </el-form-item> -->
          <el-form-item label="分享标题">
            <el-input
              clearable
              v-model="activityInfo.share_title"
              autocomplete="off"
              maxlength="20"
              show-word-limit
              placeholder="请输入分享标题"
              style="width: 30%"
            ></el-input
          ></el-form-item>
          <el-form-item label="分享图片">
            <el-upload
              class="avatar-uploader"
              action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
              :data="{
                directory: 'AGENT_INFO',
              }"
              :headers="{
                authorization: token,
              }"
              :show-file-list="false"
              :on-success="handleShareImageSuccess"
            >
              <img v-if="share_image" :src="share_image" class="avatarOther" />
              <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
                style="background-color: white"
              ></i>
            </el-upload>
            <div style="color: #909399">
              分享的图片，图片格式为jpg、png，正方形图片，大小不超过500KB
            </div>
          </el-form-item>
          <el-form-item label="分享内容">
            <el-input
              clearable
              type="textarea"
              v-model="activityInfo.share_content"
              autocomplete="off"
              show-word-limit
              placeholder="请输入分享内容"
              style="width: 30%"
            ></el-input>
          </el-form-item>
          <el-form-item label="背景音乐">
            <el-select
              v-model="activityInfo.music"
              placeholder="请选择背景音乐"
              style="width: 30%"
            >
              <template v-for="(item, index) in backMusicList">
                <el-option
                  :label="item.music_name"
                  :value="String(item.id)"
                  :key="index"
                ></el-option>
              </template>
            </el-select>
          </el-form-item>
          <el-form-item label="活动说明">
            <el-input
              clearable
              v-model="activityInfo.explain"
              autocomplete="off"
              maxlength="500"
              show-word-limit
              type="textarea"
              placeholder="请输入活动说明"
              style="width: 30%"
            ></el-input
          ></el-form-item>
          <el-form-item label="活动详情图">
            <el-upload
              class="avatar-uploader"
              action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
              :data="{
                directory: 'AGENT_INFO',
              }"
              :headers="{
                authorization: token,
              }"
              list-type="picture-card"
              multiple
              :file-list="images"
              :on-success="handleImagesSuccess"
              :on-remove="handleImagesFile"
              :before-upload="beforeAvatarUpload"
            >
              <i
                class="el-icon-plus avatar-uploader-icon"
                style="background-color: white"
              ></i>
            </el-upload>
            <div style="color: #909399">
              最多上传8张图片，图片格式为jpg或png格式，大小不能超过500KB
            </div>
          </el-form-item>
        </div>
        <div
          class="content"
          style="margin-top: 15px; border-radius: 6px; padding-bottom: 15px"
          v-if="speed === '签到规则'"
        >
          <div class="title">
            <div class="span"></div>
            签到规则
          </div>
          <div class="cutLine" style="margin-bottom: 20px"></div>
          <el-form-item label="每天签到奖励" prop="type">
            <div style="display: flex">
              <el-tooltip
                class="item"
                effect="dark"
                content="每日签到可获得的奖励，若开启递进奖励，用户连续签到次天起，开始递进奖励，断签重新计算。例：设置固定5积分，勾选次日起递增1积分，3天后不在递增；用户A第一天获得5积分，第二天连续签到获得固定5积分+额外1积分，共6积分，第三天获得7积分，积分递增计算公式为：5，5+1，5+2，5+3，5+3，5+3…，断签额外积分重新计算。"
                placement="top"
              >
                <i
                  class="el-icon-info"
                  style="margin: 13px 5px 0 0; color: #409eff"
                ></i>
              </el-tooltip>
              <div style="display: flex">
                <span style="font-size: 13px; color: black; margin-right: 8px"
                  >送</span
                >
                <el-checkbox-group
                  v-model="activityInfo.type"
                  style="margin-top: 0px"
                >
                  <el-checkbox :label="10">积分</el-checkbox>
                  <el-checkbox :label="20">储值金</el-checkbox>
                </el-checkbox-group>
                <span style="font-size: 13px; color: #606266; margin-left: 8px"
                  >(至少添加一项奖励)</span
                >
              </div>
            </div>
          </el-form-item>
          <el-form-item
            label="积分"
            style="margin-top: -15px"
            v-if="activityInfo.type.includes(10)"
          >
            <div style="font-size: 13px; display: flex">
              <el-input
                placeholder="请输入"
                style="width: 100px; margin: 0 8px"
                size="small"
                v-model="activityInfo.everyday_integral"
              />积分(每天固定奖励)
              <el-checkbox
                v-model="activityInfo.add"
                style="margin-left: 20px; margin-right: 8px"
              />递增奖励
              <span style="margin-left: 20px" v-if="activityInfo.add"
                >次日起递增<el-input
                  v-model="activityInfo.overlay_integral"
                  style="width: 100px; margin: 0 8px"
                  size="small"
                  maxlength="4"
                  show-word-limit
                />积分<el-input
                  v-model="activityInfo.stop_day_integral"
                  style="width: 100px; margin: 0 8px 0 20px"
                  size="small"
                  maxlength="4"
                  show-word-limit
                />天后，不再递增</span
              >
            </div>
          </el-form-item>
          <el-form-item
            label="储值金"
            style="margin-top: -15px"
            v-if="activityInfo.type.includes(20)"
          >
            <div style="font-size: 13px; display: flex">
              <el-input
                placeholder="请输入"
                style="width: 100px; margin: 0 8px"
                size="small"
                v-model="activityInfo.everyday_storage_money"
              />储值金(每天固定奖励)
              <el-checkbox
                v-model="activityInfo.addMoney"
                style="margin-left: 20px; margin-right: 8px"
              />递增奖励
              <span style="margin-left: 20px" v-if="activityInfo.addMoney"
                >次日起递增<el-input
                  v-model="activityInfo.overlay_storage_money"
                  style="width: 100px; margin: 0 8px"
                  size="small"
                  maxlength="4"
                  show-word-limit
                />储值金<el-input
                  v-model="activityInfo.stop_day_storage_money"
                  style="width: 100px; margin: 0 8px 0 20px"
                  size="small"
                  maxlength="4"
                  show-word-limit
                />天后，不再递增</span
              >
            </div>
          </el-form-item>
          <div
            class="cutLine"
            style="width: 95%; margin: 0 auto 15px auto"
          ></div>
          <div
            style="
              margin-left: 85px;
              line-height: 50px;
              font-size: 13px;
              color: #606266;
            "
          >
            连续签到奖励
            <el-tooltip
              class="item"
              effect="dark"
              content="用户连续签到达到设定时间点，获得的奖励，
              如果断签重新计算天数。例：设置连续签到3天送积分+储值金+奖品A，用户连续签到3天后
              将获得这3个奖励"
              placement="top"
            >
              <i class="el-icon-info" style="color: #409eff"></i> </el-tooltip
            ><el-button
              size="small"
              type="primary"
              style="margin-left: 8px"
              @click="handleAddMarketGoods"
              >添加连续签到</el-button
            >
          </div>
          <template v-for="(item, index) in market_goods">
            <div class="info" :key="index" style="margin-bottom: 10px">
              <div style="text-align: right; margin-right: 10px">
                <el-button
                  type="primary"
                  size="small"
                  @click="handleDeleteMarkGoods(index)"
                  >删除连续奖励</el-button
                >
              </div>
              <el-form-item label="连续签到第"
                ><el-input
                  placeholder="请输入"
                  v-model="item.continue_day"
                  style="width: 120px; margin-right: 8px"
                />天
                <span style="margin-left: 10px"
                  >送
                  <el-checkbox
                    style="margin: 0 8px"
                    v-model="item.is_num"
                  />积分
                  <el-checkbox
                    style="margin: 0 8px"
                    v-model="item.is_money"
                  />储值金
                  <el-checkbox
                    style="margin: 0 8px"
                    v-model="item.is_goods"
                  />奖品</span
                ></el-form-item
              >
              <div :style="'height:' + item.writeOffHeight" class="wrapper">
                <el-form-item label="积分" v-if="item.is_num">
                  <el-input
                    placeholder="请输入积分"
                    style="width: 120px; margin-right: 8px"
                    v-model="item.number"
                  />积分
                </el-form-item>
                <el-form-item label="储值金" v-if="item.is_money">
                  <el-input
                    v-model="item.money"
                    placeholder="请输入储值金"
                    style="width: 120px; margin-right: 8px"
                  />储值金
                </el-form-item>
                <el-form-item label="奖品" v-if="item.is_goods">
                  <el-button
                    type="primary"
                    size="small"
                    @click="handleAddGoods(index)"
                    >添加自定义商品</el-button
                  >
                  <!-- <el-button
                    type="primary"
                    size="small"
                    @click="handleChooseGoods(index)"
                    >添加在库商品</el-button
                  > -->
                </el-form-item>
                <div style="padding: 0 50px 0 120px" v-if="item.is_goods">
                  <el-table
                    stripe
                    :data="item.goods_list"
                    key="goods_id"
                    style="border: 1px solid #e8e8e8"
                    :row-class-name="getCellIndex"
                  >
                    <el-table-column
                      label="序号"
                      type="index"
                      width="80"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      label="奖品类型"
                      prop="type"
                      align="center"
                    >
                      <template slot-scope="scope">
                        {{ scope.row.type === 10 ? "自定义商品" : "在库商品" }}
                      </template>
                    </el-table-column>
                    <el-table-column
                      label="奖品名称"
                      prop="goods_name"
                      align="center"
                    ></el-table-column>
                    <!-- <el-table-column
                      label="奖品别名"
                      prop="goods_name_alias"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-input
                          placeholder="请输入奖品别名"
                          v-model="scope.row.goods_name_alias"
                        />
                      </template>
                    </el-table-column> -->
                    <el-table-column
                      label="奖品图片"
                      prop="goods_pic"
                      align="center"
                    >
                      <template slot-scope="scope">
                        <el-upload
                          class="avatar-uploader"
                          action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
                          :data="{
                            directory: 'AGENT_INFO',
                          }"
                          :headers="{
                            authorization: token,
                          }"
                          :show-file-list="false"
                          :on-success="
                            (res) =>
                              handleImageSuccess(res, index, scope.row.index)
                          "
                          style="margin-top: 5px"
                        >
                          <img
                            v-if="scope.row.goods_pic"
                            :src="scope.row.goods_pic"
                            class="avatarOther"
                          />
                          <i
                            v-else
                            class="el-icon-plus avatar-uploader-icon"
                            style="background-color: white"
                          ></i>
                        </el-upload>
                        <!-- <img
                        :src="scope.row.goods_pic || noPic"
                        style="
                          width: 40px;
                          height: 40px;
                          border-radius: 5px;
                          margin-top: 5px;
                        "
                      /> -->
                      </template>
                    </el-table-column>
                    <!-- <el-table-column
                      label="货号"
                      prop="goods_number"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      label="奖品类型"
                      prop="goods_number_type_name"
                      align="center"
                    ></el-table-column> -->
                    <el-table-column
                      label="奖品数量"
                      prop="goods_count"
                      align="left"
                      width="400"
                    >
                      <template slot-scope="scope">
                        <div>
                          <div
                            v-if="scope.row.type === 10"
                            style="display: flex"
                          >
                            <div v-if="market_id === 0">
                              <el-input
                                v-model="scope.row.goods_count"
                                placeholder="请输入奖品数量"
                                style="width: 100px"
                              />
                            </div>

                            <div v-if="market_id !== 0">
                              原有数量
                              <el-input
                                v-model="scope.row.goods_count"
                                placeholder="请输入奖品数量"
                                style="width: 100px"
                                disabled
                              />
                              追加数量：<el-input
                                v-model="scope.row.goods_count_new"
                                placeholder="请输入数量"
                                style="width: 100px"
                              />
                            </div>
                          </div>
                          <div v-if="scope.row.type === 20">
                            <template
                              v-for="(items, indexs) in scope.row
                                .goods_count_json"
                            >
                              <div :key="indexs">
                                <span
                                  style="font-size: 14px"
                                  v-if="market_id === 0"
                                >
                                  <el-input
                                    v-model="items.goods_count"
                                    @input="
                                      (event) =>
                                        handleGoods_count(event, items.g_count)
                                    "
                                    placeholder="请输入奖品数量"
                                    style="width: 80px"
                                  />
                                </span>
                                <span
                                  style="font-size: 14px"
                                  v-if="market_id !== 0"
                                  >原有数量：
                                  <el-input
                                    v-model="items.goods_count"
                                    @input="
                                      (event) =>
                                        handleGoods_count(event, items.g_count)
                                    "
                                    placeholder="请输入原有奖品数量"
                                    style="width: 80px"
                                    disabled
                                /></span>

                                <span
                                  style="margin-left: 10px; font-size: 14px"
                                  v-if="market_id === 0"
                                  >{{ items.name }}(实际库存：{{
                                    items.g_count
                                  }});</span
                                >
                                <span
                                  style="margin-left: 10px; font-size: 14px"
                                  v-if="market_id !== 0"
                                  >追加数量：<el-input
                                    placeholder="数量"
                                    v-if="market_id !== 0"
                                    v-model="items.goods_count_new"
                                    @input="
                                      (event) =>
                                        handleGoods_count(event, items.g_count)
                                    "
                                    style="width: 80px"
                                /></span>
                              </div>
                            </template>
                          </div>
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column label="操作" prop="action" align="center">
                      <template slot-scope="scope">
                        <el-button
                          type="text"
                          @click="
                            handleGoodsDelete(index, scope.row.index, scope.row)
                          "
                          >删除奖品</el-button
                        >
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
              <div
                style="margin: 15px 20px 0 0; text-align: right; color: #409eff"
                @click.stop="handlePutAway(index)"
                class="putAway"
              >
                {{ item.writeOffHeight !== "auto" ? "展开" : "收起" }}
              </div>
            </div>
          </template>
          <el-form-item label="核销时间"
            ><span style="color: #606266; margin-right: 8px">奖品获得后</span>
            <el-input
              placeholder="请输入"
              v-model="activityInfo.confirm_day"
              style="width: 120px; margin-right: 8px"
            />
            <span style="color: #606266; margin-right: 8px">天有效</span>
          </el-form-item>
          <el-form-item label="核销密码">
            <div style="display: flex">
              <el-tooltip
                class="item"
                effect="dark"
                content="请设置4位核销密码，核销页面输入核销密码即可进行核销"
                placement="top"
              >
                <i
                  class="el-icon-info"
                  style="margin: 12px 5px 0 0; color: #409eff"
                ></i>
              </el-tooltip>
              <el-input
                placeholder="请输入核销密码"
                v-model="activityInfo.verif_password"
                style="width: 200px; margin-right: 8px"
                maxlength="4"
                show-word-limit
              />
            </div>
          </el-form-item>
          <el-form-item label="签到规则" prop="continue_over_status">
            <div style="color: #606266; margin-right: 8px">
              超过连续签到天数后
            </div>
            <el-radio-group v-model="activityInfo.continue_over_status">
              <el-radio label="10">重新计算连续天数，继续赠送</el-radio>
              <br />
              <el-radio label="20" style="margin-top: 15px"
                >不再赠送（断签后重新达标也不再赠送）</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item label="赠送规则" prop="continue_give_status">
            <div style="color: #606266; margin-right: 8px">
              到达连续签到天数后
            </div>
            <el-radio-group v-model="activityInfo.continue_give_status">
              <el-radio label="10">送每天固定奖励+连续签到的奖励</el-radio>
              <br />
              <el-radio label="20" style="margin-top: 15px"
                >仅送连续签到的奖励</el-radio
              >
            </el-radio-group>
          </el-form-item>
        </div>
      </div>
      <div class="create">
        <div style="margin: 15px auto 0 auto;text-align: center display: flex">
          <el-button type="primary" plain @click="goBack">返回</el-button>
          <el-button
            type="primary"
            v-if="speed === '基本信息'"
            @click="nextStep"
            >下一步</el-button
          >
          <el-button
            type="primary"
            v-if="speed === '签到规则'"
            plain
            @click="lastStep"
            >上一步</el-button
          >
          <el-button
            type="primary"
            v-if="speed === '签到规则'"
            :loading="loading"
            @click="finishCommit('activityInfo')"
            >完成</el-button
          >
        </div>
      </div>
    </el-form>
    <el-dialog
      title="自定义商品"
      :visible.sync="goodsVisiable"
      width="30%"
      :close-on-click-modal="false"
    >
      <el-form
        :model="goodsInfo"
        label-width="100px"
        :rules="goods_Rules"
        ref="goodsInfo"
      >
        <el-form-item label="奖品名称" prop="goods_name">
          <el-input
            placeholder="请输入奖品名称"
            v-model="goodsInfo.goods_name"
            style="width: 60%"
            maxlength="10"
            show-word-limit
          />
        </el-form-item>
        <el-form-item label="奖品图片" required>
          <el-upload
            class="avatar-uploader"
            action="https://api.etouch.top/store/common.UploadFile/uploadOssImage"
            :data="{
              directory: 'AGENT_INFO',
            }"
            :headers="{
              authorization: token,
            }"
            :show-file-list="false"
            :on-success="handleGoodsImageSuccess"
          >
            <img v-if="goods_pic" :src="goods_pic" class="avatarOther" />
            <i
              v-else
              class="el-icon-plus avatar-uploader-icon"
              style="background-color: white"
            ></i>
          </el-upload>
          <div style="color: #909399">
            图片格式为jpg或png格式，大小不能超过500KB
          </div>
        </el-form-item>
        <el-form-item label="奖品数量" prop="goods_count">
          <el-input
            placeholder="请输入奖品数量"
            v-model="goodsInfo.goods_count"
            style="width: 60%"
          />
          <div style="color: #909399">
            注意：为避免无奖品兑换，设置的奖品库存必须保证充足
          </div>
        </el-form-item>
        <div style="text-align: right; margin-top: 10px">
          <el-button type="primary" @click="submitGoods('goodsInfo')"
            >确定</el-button
          >
          <el-button @click="cancelGoodsDialog">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <el-dialog
      :fullscreen="false"
      title="筛选货品"
      :visible.sync="chooseGoodsVisiable"
      width="90%"
      :before-close="handleClose"
      ><ChooseGoods
        @getGoods="getGoodsItem"
        v-if="chooseGoodsVisiable"
        :prizeList="chooseGoodsList"
        :type="1"
    /></el-dialog>
  </div>
</template>

<script>
import ChooseGoods from "../../activity/activityList/chooseGood";
import { getGoodsByHierarchy } from "@/api/marketing/activity/index";
import { getMusic } from "@/api/marketing/activity/index";
import { getBindingClientSystemList } from "@/api/member/system/Index.js";
import {
  getMarketTemplateBasic,
  detail,
  create,
  update,
} from "@/api/marketing/basicActivity/index.js";
import Cookies from "js-cookie";
import storage from "good-storage";
const noPic = require("@/assets/images/nopic.png");

export default {
  data() {
    let validateNum = (rule, value, callback) => {
      if (!/^\d+$/.test(value)) {
        callback(new Error("请输入正整数"));
      } else {
        callback();
      }
    };
    return {
      loading: false,
      noPic: noPic,
      token: "",
      activityInfo: {},
      chooseGoodsVisiable: false,
      rules: {
        title: [
          {
            required: true,
            message: "请输入活动名称",
            trigger: "blur",
          },
        ],
        client_system_id: [
          {
            required: true,
            message: "请选择会员体系",
            trigger: "change",
          },
        ],
        continue_over_status: [
          {
            required: true,
            message: "请选择签到规则",
            trigger: "change",
          },
        ],
        continue_give_status: [
          {
            required: true,
            message: "请选择赠送规则",
            trigger: "change",
          },
        ],
        type: [
          {
            required: true,
            message: "请选择每天签到奖励",
            trigger: "change",
          },
        ],
      },
      goods_Rules: {
        goods_name: [
          {
            required: true,
            message: "请输入商品名称",
            trigger: "blur",
          },
        ],
        goods_count: [
          {
            required: true,
            message: "请输入商品数量",
            trigger: "blur",
          },
          { validator: validateNum, trigger: "blur" },
        ],
      },
      speed: "基本信息",
      head_image: "",
      share_image: "",
      backMusicList: [],
      images: [],
      activityImage: [],
      goodsInfo: {},
      goodsVisiable: false,
      goods_pic: "",
      systemList: [],
      goods_index: 0,
      chooseGoodsList: [],
      market_goods: [],
      market_index: null,
    };
  },
  components: {
    ChooseGoods,
  },
  props: {
    market_id: Number,
  },
  created() {
    this.token = storage.session.get("token") || Cookies.get("token");

    this.getBackMusic();
    this.getBindingClientSystemList();

    if (this.market_id !== 0) {
      this.getSignInfo();
    }

    if (this.market_id === 0) {
      this.getMarketTemplateBasic();
    }
  },

  methods: {
    goBack() {
      this.$emit("hide");
    },
    getCellIndex({ row, rowIndex }) {
      row.index = rowIndex;
      return row;
    },
    /*
     *  获取签到模板信息
     */
    getMarketTemplateBasic() {
      getMarketTemplateBasic({ market_template_id: 12 }).then((res) => {
        if (res.code === 1) {
          this.activityInfo = {
            type: [],
            title: res.data.market_template_title,
            verif_password: res.data.verif_password,
            ...res.data.market_template_default_json,
          };
          this.share_image = res.data.market_template_default_json.share_image;
        }
      });
    },
    handlePutAway(i) {
      this.market_index = i + 1;
      this.market_goods = this.market_goods.map((item, index) => {
        if (i === index) {
          item.writeOffHeight = item.writeOffHeight === "0px" ? "auto" : "0px";
        }
        return item;
      });
    },
    getSignInfo() {
      detail({ market_id: this.market_id,market_template_category_id:4}).then((res) => {
        if (res.code === 1) {
          this.activityInfo = {
            ...res.data,
            music: String(res.data.music),
            ...res.data.market_json,
            type: [],
            add:
              res.data.market_json.overlay_integral &&
              res.data.market_json.overlay_integral !== 0
                ? true
                : false,
            addMoney:
              res.data.market_json.overlay_storage_money &&
              res.data.market_json.overlay_storage_money !== 0
                ? true
                : false,
          };
          if (
            res.data.market_json.everyday_integral &&
            res.data.market_json.everyday_integral !== 0
          ) {
            this.activityInfo.type.push(10);
          }
          if (
            res.data.market_json.everyday_storage_money &&
            res.data.market_json.everyday_storage_money !== 0
          ) {
            this.activityInfo.type.push(20);
          }
          this.head_image = res.data.head_image;
          this.share_image = res.data.share_image;
          this.images =
            res.data.images === ""
              ? []
              : res.data.images.split(",").map((item) => {
                  var info = {};
                  info.url = item;
                  return info;
                });
          this.activityImage = res.data.images.split(",");

          res.data.market_goods.map((item) => {
            item.goods_list.forEach((items) => {
              if (items.type === 40) {
                item.number_id = items.market_sign_goods_id;
                item.is_num = true;
                item.number = items.market_value;
              }
              if (items.type === 50) {
                item.money_id = items.market_sign_goods_id;
                item.is_money = true;
                item.money = items.market_value;
              }
              if (items.type === 10 || items.type === 20) {
                item.is_goods = true;
              }
            });
            item.goods_list = item.goods_list.filter(
              (item) => item.type === 10 || item.type === 20
            );
            item.goods_list = item.goods_list.map((goods) => {
              return goods;
            });
            item.writeOffHeight = "auto";
            return item;
          });
          console.log(res.data.market_goods);
          this.market_goods = JSON.parse(JSON.stringify(res.data.market_goods));
        }
      });
    },
    handleGoodsDelete(i, goods_index, row) {
      this.$confirm("确定要删除该奖品吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.market_goods.map((item, index) => {
          if (index === i) {
            item.goods_list = item.goods_list.filter(
              (items, indexs) => indexs !== goods_index
            );
          }
          return item;
        });
        this.chooseGoodsList = this.chooseGoodsList.filter(
          (item) => row && item.goods_id !== row.goods_id
        );
      });
    },
    /*
     *  会员体系下拉数据
     */
    getBindingClientSystemList() {
      getBindingClientSystemList().then((res) => {
        if (res.code === 1) {
          this.systemList = res.data;
        }
      });
    },
    nextStep() {
      if (!this.activityInfo.title) {
        this.$message({
          message: "请完善活动名称",
          type: "info",
        });
      } else if (!this.activityInfo.client_system_id) {
        this.$message({
          message: "请选择会员体系",
          type: "info",
        });
      } else {
        this.speed = "签到规则";
      }
    },
    lastStep() {
      this.speed = "基本信息";
    },
    /*
     *  背景音乐
     */
    getBackMusic() {
      getMusic().then((res) => {
        if (res.code === 1) {
          this.backMusicList = res.data;
        }
      });
    },
    /*
     *  头图上传
     */
    handleHeadImageSuccess(res, file) {
      this.head_image = res.data;
    },
    /*
     *  分享图片上传
     */
    handleShareImageSuccess(res, file) {
      this.share_image = res.data;
    },
    handleImageSuccess(res, i, rowIndex) {
      this.market_goods = this.market_goods.map((item, index) => {
        if (index === i) {
          item.goods_list = item.goods_list.map((goods, goodsIndex) => {
            if (goodsIndex === rowIndex) {
              goods.goods_pic = res.data;
            }
            return goods;
          });
        }
        return item;
      });
    },
    beforeAvatarUpload() {
      const isLength = this.images.length < 8;
      if (!isLength) {
        this.$message({
          message: "最多上传8张",
          type: "error",
        });
      }
      return isLength;
    },
    /*
     *  上传活动详情图
     */
    handleImagesSuccess(res, file) {
      this.images.push({
        url: res.data,
      });
      this.activityImage.push(res.data);
    },
    /*
     *  奖品图片
     */
    handleGoodsImageSuccess(res) {
      this.goods_pic = res.data;
    },
    /*
     *  删除活动详情图片
     */
    handleImagesFile(res, file) {
      this.images = this.images.filter((item) => {
        return item.url !== res.url;
      });
      this.activityImage = this.activityImage.filter((item) => {
        return item !== res.url;
      });
    },
    /*
     *  添加连续签到奖励
     */
    handleAddMarketGoods() {
      if (this.market_goods.length < 8) {
        this.market_goods.push({
          writeOffHeight: "auto",
          continue_day: null,
          goods_list: [],
        });
      } else {
        this.$message({
          message: "最多添加8个",
          type: "info",
        });
      }
    },
    /*
     *  关闭自定义商品弹窗
     */
    cancelGoodsDialog() {
      this.goods_index = 0;
      this.goodsVisiable = false;
      this.goodsInfo = {};
      this.goods_pic = "";
    },
    /*
     *  添加自定义商品
     */
    submitGoods(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.goods_pic === "") {
            this.$message.error("请上传奖品图片");
            return;
          }
          this.market_goods = this.market_goods.map((item, index) => {
            if (index + 1 === this.goods_index) {
              item.goods_list.push({
                type: 10,
                ...this.goodsInfo,
                goods_pic: this.goods_pic,
              });
            }
            return item;
          });
          this.$set(this.market_goods);
          this.cancelGoodsDialog();
        }
      });
    },
    handleAddGoods(index) {
      if (this.market_goods[index].goods_list.length < 8) {
        this.goods_index = index + 1;
        this.goodsVisiable = true;
      } else {
        this.$message({
          message: "最多添加8个奖品",
          type: "warning",
        });
      }
    },
    handleClose() {
      this.chooseGoodsVisiable = false;
    },
    getGoodsItem(data) {
      var arr = [{ ...data }];
      getGoodsByHierarchy({
        hierarchy_id: data.hierarchy_id,
        goods_number: data.goods_number,
      }).then((res) => {
        if (res.code === 1) {
          arr = arr.map((item, index) => {
            const info = {
              type: 20,
              goods_count_json: res.data.map((i) => {
                i.goods_count = i.g_count;
                return i;
              }),
              goods_name: item.goods_name,
              goods_name_alias: item.goods_name,
              goods_pic: item.goods_pic,
              goods_count: item.goods_count,
              goods_id: item.goods_id,
              goods_number: item.goods_number,
              goods_number_type_name: item.goods_number_type_name,
              goods_number_type: item.goods_number_type,
            };

            return info;
          });
          this.chooseGoodsList = this.chooseGoodsList.concat(arr);
          this.market_goods = this.market_goods.map((item, index) => {
            if (this.goods_index === index + 1) {
              item.goods_list = item.goods_list.concat(arr);
            }

            return item;
          });
          this.$set(this.market_goods);
          this.chooseGoodsVisiable = false;
        }
      });
    },
    handleChooseGoods(index) {
      if (this.market_goods.length < 8) {
        this.goods_index = index + 1;
        this.chooseGoodsVisiable = true;
      } else {
        this.$message({
          message: "最多添加8个奖品",
          type: "warning",
        });
      }
    },
    handleDeleteMarkGoods(i) {
      this.$confirm("确定要删除该连续签到吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$message({
          message: "删除成功",
          type: "success",
        });
        this.market_goods = this.market_goods.filter(
          (item, index) => index !== i
        );
      });
    },
    handleGoods_count(e, g_count) {
      if (Number(e) > Number(g_count)) {
        this.$message({
          type: "error",
          message: "奖品数量不能大于实际库存数量",
        });
      }
    },
    finishCommit() {
      this.$refs.activityInfo.validate((valid) => {
        console.log(valid)
        if (valid) {
          // this.loading = true;
          if (this.activityInfo.type.includes(10)) {
            if (!this.activityInfo.everyday_integral) {
              this.$message({
                message: "请输入每天签到奖励中的积分值",
                type: "warning",
              });
              return;
            }
          }
          if (this.activityInfo.type.includes(20)) {
            if (!this.activityInfo.everyday_storage_money) {
              this.$message({
                message: "请输入每天签到奖励中的储值金",
                type: "warning",
              });
              return;
            }
          }
          if (this.activityInfo.add) {
            if (!this.activityInfo.overlay_integral) {
              this.$message({
                message: "请输入每天签到奖励中，次日起递增的积分值",
                type: "warning",
              });
              return;
            }
          }
          if (this.activityInfo.addMoney) {
            if (!this.activityInfo.overlay_storage_money) {
              this.$message({
                message: "请输入每天签到奖励中，次日起递增的储值金",
                type: "warning",
              });
              return;
            }
          }
          var market_goods = this.market_goods.map((item, index) => {
            let list = JSON.parse(JSON.stringify(item.goods_list));
            if (item.is_num) {
              const info = {
                type: 40,
                market_value: item.number,
                sort: index + 1,
              };
              if (this.market_id !== 0) {
                info.market_sign_goods_id = item.number_id;
              }
              list.push(info);
            }

            if (item.is_money) {
              const info = {
                type: 50,
                market_value: item.money,
                sort: index + 1,
              };
              if (this.market_id !== 0) {
                info.market_sign_goods_id = item.money_id;
              }
              list.push(info);
            }
            list = list.map((goods, i) => {
              if (goods.type === 20) {
              }
              goods.sort = i + 1;
              return goods;
            });
            return {
              continue_day: item.continue_day,
              goods_list: list,
            };
          });

          const data = {
            market_template_id: 12,
            market_template_function: 20,
            title: this.activityInfo.title,
            client_system_id: this.activityInfo.client_system_id,
            head_image: this.head_image,
            share_title: this.activityInfo.share_title,
            share_content: this.activityInfo.share_content,
            share_image: this.share_image,
            music: this.activityInfo.music,
            explain: this.activityInfo.explain,
            images: this.activityImage.join(","),
            confirm_day: this.activityInfo.confirm_day,
            verif_password: this.activityInfo.verif_password,
            market_template_category_id:4,
            market_json: {
              continue_over_status: this.activityInfo.continue_over_status,
              continue_give_status: this.activityInfo.continue_give_status,
            },
            market_goods,
          };
          if (this.activityInfo.type && this.activityInfo.type.includes(10)) {
            data.market_json.everyday_integral =
              this.activityInfo.everyday_integral;
            data.market_json.overlay_integral =
              this.activityInfo.overlay_integral;
            data.market_json.stop_day_integral =
              this.activityInfo.stop_day_integral;
          }
          if (this.activityInfo.type && this.activityInfo.type.includes(20)) {
            data.market_json.everyday_storage_money =
              this.activityInfo.everyday_storage_money;
            data.market_json.overlay_storage_money =
              this.activityInfo.overlay_storage_money;
            data.market_json.stop_day_storage_money =
              this.activityInfo.stop_day_storage_money;
          }
          if (this.market_id === 0) {
            create(data)
              .then((res) => {
                if (res.code === 1) {
                  this.$message({
                    type: "success",
                    message: "保存成功",
                  });
                  this.loading = false;
                  this.$emit("hide");
                }
              })
              .catch((res) => {
                this.loading = false;
              });
          } else {
            data.market_id = this.market_id;
            update(data)
              .then((res) => {
                if (res.code === 1) {
                  this.$message({
                    type: "success",
                    message: "更新成功",
                  });
                  this.loading = false;
                  this.$emit("hide");
                }
              })
              .catch((res) => {
                this.loading = false;
              });
          }
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.wrapper {
  transition: all 0.3s;
  overflow: hidden;
  flex: 1;
}
.putAway:hover {
  cursor: pointer;
}
/deep/ .el-upload--picture-card {
  width: 70px;
  height: 70px;
  line-height: 80px;
}
/deep/ .el-upload-list--picture-card .el-upload-list__item {
  width: 70px;
  height: 70px;
}
/deep/.avatar-uploader .el-upload {
  width: 70px;
  height: 70px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
/deep/ .avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
// /deep/ .el-upload--picture-card {
//   line-height: 115px !important;
// }
/deep/ .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 70px;
  height: 70px;
  line-height: 70px;
  text-align: center;
}

/deep/ .avatarOther {
  width: 70px;
  height: 70px;
  display: block;
}
.speed {
  display: flex;
  height: 58px;
  background: linear-gradient(0deg, #f3f9ff 1%, #ffffff 100%);
  border: 1px solid #e8e8e8;
  border-radius: 7px 7px 0 0;
  line-height: 58px;
  font-size: 16px;

  .li {
    width: 50%;
    text-align: center;
    color: #909399;
    img {
      margin-right: 5px;
      margin-top: -3px;
      width: 20px;
      height: 20px;
      vertical-align: middle;
    }
    i {
      float: right;
      margin-top: 22px;
      border: solid#c3c3c3;
      border-width: 0 1px 1px 0;
      display: inline-block;
      padding: 6px;
    }
    .right {
      transform: rotate(-45deg);
      -webkit-transform: rotate(-45deg);
    }
  }
}
.title {
  display: flex;
  flex-direction: row;
  padding-left: 20px;
  height: 50px;
  line-height: 50px;
  font-size: 14px;
  font-weight: 600;

  .span {
    margin: auto 6px auto 0;
    width: 4px;
    height: 12px;
    background: #5493f5;
    border-radius: 2px;
  }
}
.cutLine {
  width: 100%;
  height: 1px;
  background: #e8e8e8;
}
.create {
  height: 64px;
  position: fixed;
  bottom: 0;
  right: 0;
  left: 70px;
  text-align: center;
  background-color: white;
  box-shadow: 0px 1px 3px 1px rgba(153, 153, 153, 0.1);
}
.info {
  position: relative;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-left: 80px;
  width: 90%;
  background: #f9f9f9;
}
</style>
